export type AccountRegContactType = `Business` | `Federal Government` | `Local/State Government` | `Personal` | `Web Access` | ``;
export type AccountType = `Account` | `District` | `Division` | `Parent`;
export type LoginType = `modal` | `page`;
export type AccountBusType =
    | ``
    | `agri`
    | `auto`
    | `buscoach`
    | `constrct`
    | `heavyequ`
    | `manu`
    | `other`
    | `trkdeal`
    | `trkrep`
    | `wholedis`;
export type AccountRegType =
    | ``
    | `agri`
    | `auto`
    | `bus`
    | `buscoach`
    | `constrct`
    | `existing`
    | `federalgov`
    | `heavyequ`
    | `ind`
    | `localgov`
    | `manu1`
    | `manu2`
    | `other`
    | `trkdeal`
    | `trkrep`
    | `wholedis`;

export type WebSessionProp =
    | `activeCatalogId`
    | `drilledAccount`
    | `isTransient`
    | `keyIdString`
    | `orderLines`
    | `orderNumber`
    | `selectedContact`
    | `snowflake_id`
    | `UIDHash`;

export type JobCostingPlatform = `cdk` | `fastrack` | `imperial`;

export const blockedEmailRegex = /^(sap|user|users|demo|test)@/i;

export interface Account {
    account: string;
    accounts?: Account[];
    attn: string;
    city: string;
    contact: string;
    custAcct: string;
    hidden?: boolean;
    hideLogin?: boolean;
    name: string;
    orders?: OrdersListObject;
    st: string;
    type: AccountType;
    zip: string;
}

export interface AccountDropdown {
    listing: AccountKey[];
    result: string;
    type: AccountType;
}

interface AccountKey {
    acct: string;
    key: string;
    name: string;
}

export interface AuthenticateParams {
    login: string;
    loginType: LoginType;
    Password: string;
    reauthenticate: boolean;
    stayauthed?: `on`;
}

export interface AuthenticateRes {
    pickAccount: boolean;
}

export interface Catalog {
    cid: number;
    default: boolean;
    keyid: number;
    name: string;
}

export interface CatalogList {
    catalogs: Catalog[];
    nbrCatalogs: number;
}

export interface CreateAccountParams {
    [`g-recaptcha-response`]?: string;
    accountType: AccountRegType;
    captchaID?: string;
    captchaValue?: string;
    contactCompany: string;
    contactEmail: string;
    contactName: string;
    contactPassword: string;
    contactPhone: string;
    ip: string;
    ipLocation: string;
    optin: boolean;
    shipToAddr1: string;
    shipToAddr2: string;
    shipToCity: string;
    shipToState: string;
    shipToZipCode: string;
}

export interface CreateAccountRes {
    exists: boolean;
    newAccount?: string;
    rep: number;
    repEmail: string;
    repName: string;
    result: string;
    xcheckMatches?: XCheckMatch[];
}

export interface CreateWebLoginParams {
    [`g-recaptcha-response`]: string;
    account: string;
    contactEmail: string;
    contactName: string;
    contactPassword: string;
    contactPhone: string;
    invoiceNbr: string;
    optin: boolean;
}

export interface CreateWebLoginRes {
    badDomain?: boolean;
    error?: string;
    loginCreated: boolean;
    msg?: any;
    name: string;
    newAccess: boolean;
    phoneNbr: string;
    primaryEmail: string;
    primaryName: string;
    repEmail: string;
    repName: string;
    result: string;
}

export interface OnboardUserParams {
    acceptedTerms: boolean;
    email: string;
    emailPermission: boolean;
    formFields: string[];
    fullName: string;
    pageID: string;
    phoneNbr: string;
    subject: string;
    workflow_id: string;
}

export interface OrdersListObject {
    orderCount: number;
    orders?: string[];
    pendCount: number;
}

export interface ForgotPasswordFormRes {
    displayableError: string;
    requestComplete: boolean;
}

export interface Rep {
    extension: string;
    fax: string;
    login: string;
    mailto: string;
    name: string;
    phone: string;
    title: string;
}

export interface UserWebSession {
    activeCatalogId?: string;
    drilledAccount?: string;
    isTransient?: string;
    keyIdString?: string;
    orderLines?: string;
    orderNumber?: string;
    selectedContact?: string;
    snowflake_id?: string;
    UIDHash?: string;
}

export interface XCheckMatch {
    account: string;
    contactID: number;
    rule: string;
}
